import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import Login from 'modules/auth/components/PageLogin';

const Profile = () => {
  const [user, updateUser] = useState(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        updateUser(user);
      })
      .catch((err) => updateUser(null));
  }, []);
  return <Login />;
};

export default Profile;
